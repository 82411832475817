import React from 'react'
import Layout from '../components/Layout'
import SectionHeaderImage from '../components/common/SectionHeaderImage'
import image_background from '../images/solutions/publicidad.png'
import FooterContact from './../components/FooterContact'
import PageContentPublicidad from '../components/Solutions/Pages/Publicidad'

const Publicidad = props => (
  <Layout
    title="Servicio de Publicidad"
    description="Tenemos una amplia experiencia en campañas publicitarias a través de herramientas sofisticadas que nos ayudan a posicionar su marca y refrescar su imagen corporativa."
    {...props}
  >
    <SectionHeaderImage image={image_background} position="90% 0" />
    <PageContentPublicidad />
    <FooterContact />
  </Layout>
)

export default Publicidad
